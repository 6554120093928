import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/Layout'

function Accompaniments() {
  return (
    <Layout>
      <div className="px-16 py-4 bg-light-grey" style={{ minHeight: '85vh' }}>
        <h2 className="flex justify-center my-8 font-bold text-center">
          Hymn Accompaniments
        </h2>
        <div className="max-w-6xl mx-auto text-justify space-y-4">
          <p>
            Here you will find piano accompaniments for most of the hymns in the
            Trinity Hymnal (1990). Here are two suggestions on how these
            accompaniments can be used effectively:
          </p>
          <ol className="space-y-4">
            <li>
              You can read the text of a hymn while listening to the musical
              accompaniment, as a part of private worship or as an aid to
              memorizing the text;
            </li>
            <li>
              A family, or other group of believers, can sing together some of
              the great hymns even though lacking the resources needed for
              musical accompaniment. It is hoped that many will come to realize
              the deep satisfaction of singing praise to God as a part of social
              gatherings in the home.
            </li>
          </ol>
          <div className="flex justify-center my-2">
            <Link
              to="/search-for-accompaniments"
              className="bg-orange-700 hover:bg-orange-600 text-white transition duration-200 ease-in-out px-4 py-1 rounded-md"
            >
              Search for Accompaniments
            </Link>
          </div>
          <p>
            The accompaniments are unadorned in order that the focus might be on
            the texts. For the same reason the accompaniments are unhurried. It
            is our conviction that in many hymn-singing churches, the
            accompaniments are played so fast that the congregation struggles
            just to sing the words, leaving little time for even momentary
            reflection on the meaning of the texts. Without that contemplation
            of the text, congregational singing, in our judgment, has lost its
            justification for inclusion in worship.
          </p>
          <p>
            A number of the accompaniments end with an "Amen." The tradition is
            that any hymn in which the Trinity is mentioned in a significant
            way, should end with an "Amen." "Holy, Holy, Holy," and "Now Thank
            We All Our God" are two examples. When the accompaniment for a hymn
            is to include an "Amen," you will hear a low-note after the final
            chord is sounded.
          </p>
          <p>
            As a result of the renovation of NeuSong.com, the search process for
            accompaniments is now more user-friendly, and much faster. In the
            listing of the accompaniments, we have included the name of the
            hymntune; that is, the name by which the music of the hymn is known
            to musicians. It is now possible to search the collection by
            hymntune name. For example, for the majestic hymn, "Our God, Our
            Help in Ages Past," the hymntune that is almost always used is known
            as St. Anne. If you choose to search the accompaniments collection
            by hymntune and you type St. Anne in the Search by Hymntune box, you
            will immediately see the two hymns in the Trinity Hymnal that use
            this hymntune, one of which is “Our God, Our Help in Ages Past."
          </p>
          <p>
            Here is one advantage of being able to search the collection by
            hymntune name. Suppose you find in another hymnal the hymn “God
            Calling Yet," which is not in the Trinity Hymnal. You will probably
            see, for this hymn, the hymntune name, probably, Federal Street. If
            you search our collection of accompaniments by that hymntune, you
            will find that it is used three times. Choose any one of them and
            you will have the desired accompaniment for “God Calling Yet." It
            might happen that there are more (or, fewer) verses in the
            accompaniment than in your hymnal, but that is not hard to get
            around.
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default Accompaniments
